import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useDispatch } from 'react-redux';
// import { addMonths, format } from 'date-fns';
import { addHours, addMonths, format, addDays } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FilterModal from '../components/Filter';
import FaqsMappingList from '../components/FaqsMappingList';
import faqsApi from '../api/faqs.api';
import { PRODUCT_TYPES } from '../constants';

function CreateFaqMappingPopup({ open, onClose, onSave }) {
  const [faq, setFaq] = useState({
    mappingType: 'CLIENT',
    mappingId: '',
    tagIds: [],
    faqsList: [],
    appRegion: process.env.REACT_APP_DEFAULT_REGION,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFaq({ ...faq, [name]: value });
  };

  const handleSave = () => {
    onSave(faq); // Call the function passed from parent to save the FAQ data
    onClose(); // Close the popup after saving
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New FAQ Mapping</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Mapping Type</InputLabel>
          <Select
            name="mappingType"
            value={faq.mappingType}
            onChange={handleInputChange}
          >
            <MenuItem value="CLIENT">CLIENT</MenuItem>
            <MenuItem value="PRODUCT_TYPE">PRODUCT TYPE</MenuItem>
          </Select>
        </FormControl>
        {faq.mappingType === 'CLIENT' ? (
          <TextField
            name="mappingId"
            label="Mapping ID"
            fullWidth
            value={faq.mappingId}
            onChange={handleInputChange}
            margin="normal"
          />
        ) : faq.mappingType === 'PRODUCT_TYPE' ? (
          <FormControl fullWidth margin="normal">
            <InputLabel>Mapping ID</InputLabel>
            <Select
              name="mappingId"
              value={faq.mappingId}
              onChange={handleInputChange}
              MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
            >
              {Object.entries(PRODUCT_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function FaqsMapping() {
  const [filter, setFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch();

  const handleCreateFaqMapping = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleSaveFaqMapping = async (faqData) => {
    try {
      const result = await faqsApi.createFaqsMapping(dispatch, faqData);
      console.log('result', result._id, result);
      setSnackbarMessage('FAQ Mapping added successfully');
      setSnackbarOpen(true);
      setOpenPopup(false);
      setTimeout(() => {
        window.open(`/faqs-mapping/${result._id}`, '_blank');
      }, 2000);
    } catch (error) {
      setSnackbarMessage('Failed to add FAQ Mapping');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    let dateDisplay = '';

    if (filterAfterDate) {
      const afterDateIso = new Date(filterAfterDate).toISOString(); // Convert to ISO string
      newFilter.createdAt = { $gte: afterDateIso };
      dateDisplay = `Dates: ${format(new Date(filterAfterDate), 'MM/dd/yyyy')}`;
    }

    if (filterBeforeDate) {
      const beforeDateIso = new Date(filterBeforeDate).toISOString(); // Convert to ISO string
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: beforeDateIso,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(
          new Date(filterBeforeDate),
          'MM/dd/yyyy'
        )}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(
          new Date(filterBeforeDate),
          'MM/dd/yyyy'
        )}`;
      }
    }

    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  useEffect(() => {
    if (applyQuickFilter) {
      setApplyQuickFilter(false);
      saveFilters();
    }
  }, []);

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilter({});
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
  };

  return (
    <div>
      <div>
        <h5 className="d-flex align-content-center align-items-center mb-0">
          <div className="flex-grow-1" />

          {filter && !Object.keys(filter).length && (
            <>
              <Button
                color="primary"
                onClick={handleCreateFaqMapping}
                variant="contained"
                sx={{
                  padding: '6px 16px',
                  fontSize: '0.875rem',
                  textTransform: 'none',
                }}
              >
                Create FAQ Mapping
              </Button>

              <CreateFaqMappingPopup
                open={openPopup}
                onClose={handleClosePopup}
                onSave={handleSaveFaqMapping}
                currentUser
              />
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}

          <FilterModal
            currentFilters={filter}
            onSave={saveFilters}
            itemName="Stats"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h5>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div className="row my-2 ">
        <FaqsMappingList filter={filter} viewHeight="75vh" />
      </div>
    </div>
  );
}

export default FaqsMapping;
