import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Snackbar } from '@mui/material';
import faqsApi from '../api/faqs.api';
import { PRODUCT_TYPES } from '../constants';
import MappingEditForm from '../components/MappingEditForm';
import MappingTagsSection from '../components/MappingTagsSection';
import MappingFaqsSection from '../components/MappingFaqsSection';

function FaqsMappingDetails() {
  const dispatch = useDispatch();
  const { id } = useParams(); // Extract the mapping ID from the URL
  const [isLoading, setIsLoading] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState({ mappingType: '', mappingId: '' });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const [details, setDetails] = useState(null);
  const [tagsList, setTagsList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [availableTags, setAvailableTags] = useState([]);
  const [faqList, setFaqList] = useState([]);
  const [selectedFaqs, setSelectedFaqs] = useState([]);
  const [selectedFaqTags, setSelectedFaqTags] = useState({});
  const [isFaqDialogOpen, setIsFaqDialogOpen] = useState(false);
  const [editingFaq, setEditingFaq] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [detailsResponse, tagsResponse, faqsResponse] = await Promise.all(
          [
            faqsApi.getFaqsMappingByDetailsById(dispatch, id),
            faqsApi.getFaqsTagsList(),
            faqsApi.getFaqs(),
          ]
        );
        setDetails(detailsResponse || {});
        setTagsList(tagsResponse.data || []);
        setFaqList(faqsResponse.data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    if (details && tagsList.length > 0) {
      // Ensure selected tags are correctly set based on the tagIds
      const selected = tagsList.filter((tag) =>
        details.tagIds.includes(tag._id)
      );
      setSelectedTags(selected);
      // Exclude selected tags to build availableTags list
      const remainingTags = tagsList.filter(
        (tag) => !details.tagIds.includes(tag._id)
      );
      setAvailableTags(remainingTags);
      const preSelectedFaqs = details.viewModel.faqs.map((faq) => ({
        value: faq._id,
        label: faq.question, // Ensure `question` is available in the object
        authorName: faq.authorName,
        visibility: faq.visibility,
        isActive: faq.isActive,
        answer: faq.answer,
        answerType: faq.answerType,
      }));

      // Extract associated tags for each FAQ
      const preSelectedFaqTags = details.viewModel.faqs.reduce((acc, faq) => {
        acc[faq._id] = faq.tags.map((tag) => ({
          value: tag._id,
          label: tag.label,
        }));
        return acc;
      }, {});

      setSelectedFaqs(preSelectedFaqs);
      setSelectedFaqTags(preSelectedFaqTags);
    }
  }, [details, tagsList]);

  const handleEditClick = () => {
    setEditData({
      mappingType: details.mappingType,
      mappingId: details.mappingId,
    });
    setEditOpen(true);
  };
  const handleCloseDialog = () => {
    setEditOpen(false);
  };
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveEdit = async (updateData) => {
    try {
      await faqsApi.updateFaqsMapping(dispatch, id, updateData);
      setDetails((prev) => ({ ...prev, ...updateData }));
      setSnackbar({
        open: true,
        message: 'Faq Mapping updated successfully!',
        severity: 'success',
      });
      handleCloseDialog();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update the faq mapping.',
        severity: 'error',
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (isLoading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (!details) {
    return (
      <Typography variant="h6">No details found for this mapping.</Typography>
    );
  }

  const handleTagChange = (selectedOptions) => {
    const updatedTags = selectedOptions.map((option) => ({
      _id: option._id,
      label: option.label,
    }));
    setSelectedTags(updatedTags);
    const updatedAvailableTags = tagsList.filter(
      (tag) => !updatedTags.some((selectedTag) => selectedTag._id === tag._id)
    );
    setAvailableTags(updatedAvailableTags);
  };

  // Save updated tags to API
  const handleSaveTags = async (selectedTags1) => {
    try {
      const updatedTagIds = selectedTags1.map((tag) => tag._id);
      await faqsApi.updateFaqsMapping(dispatch, id, { tagIds: updatedTagIds });

      setDetails((prev) => ({ ...prev, tagIds: updatedTagIds }));
      setSnackbar({
        open: true,
        message: 'Tag updated successfully!',
        severity: 'success',
      });
      setIsEditing(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update the tag.',
        severity: 'error',
      });
    }
  };

  const handleFaqChange = (selectedOptions) => {
    setSelectedFaqs(selectedOptions);
    setSelectedFaqTags((prev) => {
      const updatedTags = { ...prev };
      selectedOptions.forEach((faq) => {
        if (!updatedTags[faq.value]) {
          updatedTags[faq.value] = [];
        }
      });
      return updatedTags;
    });
  };

  const handleFaqTagChange = (faqId, selectedOptions) => {
    if (!faqId) {
      console.error('FAQ ID is undefined');
      return;
    }
    const updatedTags = selectedOptions
      ? selectedOptions.map((option) => ({
          value: option.value,
          label: option.label,
        }))
      : [];
    setSelectedFaqTags((prev) => ({
      ...prev,
      [faqId]: updatedTags,
    }));
  };

  const handleSaveFaqMapping = async (
    updatedFaqs = selectedFaqs,
    updatedTags = selectedFaqTags
  ) => {
    const updatedFaqsList = updatedFaqs.map((faq) => ({
      faqId: faq.value,
      faqTagIds: updatedTags[faq.value]
        ? updatedTags[faq.value].map((tag) => tag.value)
        : [],
    }));
    try {
      await faqsApi.updateFaqsMapping(dispatch, id, {
        faqsList: updatedFaqsList,
      });
      setSnackbar({
        open: true,
        message: 'Mapping updated successfully!',
        severity: 'success',
      });
      setIsFaqDialogOpen(false);
      // Refresh UI
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update the mapping.',
        severity: 'error',
      });
    }
  };

  const handleSaveTagsForFaq = async (faqId) => {
    if (!faqId) return;

    try {
      // Preserve previously selected FAQs and merge with the updated one
      const updatedFaqsList = Object.keys(selectedFaqTags).map((faq) => ({
        faqId: faq,
        faqTagIds: selectedFaqTags[faq].map((tag) => tag.value) || [],
      }));
      await faqsApi.updateFaqsMapping(dispatch, id, {
        faqsList: updatedFaqsList,
      });
      setSnackbar({
        open: true,
        message: 'FAQ Tag Mapping Updated!',
        severity: 'success',
      });
      // Optionally, exit edit mode
      setEditingFaq(null);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update the mapping.',
        severity: 'error',
      });
    }
  };

  return (
    <>
      <Typography variant="h5" style={{ textAlign: 'left' }}>
        FAQ Mapping
      </Typography>
      <MappingEditForm
        details={details}
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        editData={editData}
        setEditData={setEditData}
        handleEditChange={handleEditChange}
        handleSaveEdit={handleSaveEdit}
        handleEditClick={handleEditClick}
        handleCloseDialog={handleCloseDialog}
        PRODUCT_TYPES={PRODUCT_TYPES}
      />
      <br />
      <MappingTagsSection
        selectedTags={selectedTags}
        availableTags={availableTags}
        onTagChange={handleTagChange}
        onSaveTags={handleSaveTags}
        isEditing={isEditing} // Pass the state for editing
        setIsEditing={setIsEditing}
      />
      <br />
      <MappingFaqsSection
        details={details}
        faqList={faqList}
        selectedFaqs={selectedFaqs}
        setSelectedFaqs={setSelectedFaqs}
        selectedFaqTags={selectedFaqTags}
        setSelectedFaqTags={setSelectedFaqTags}
        onFaqTagChange={handleFaqTagChange}
        onSaveFaqMapping={handleSaveFaqMapping}
        onFaqChange={handleFaqChange}
        isFaqDialogOpen={isFaqDialogOpen} // Pass the state for editing
        setIsFaqDialogOpen={setIsFaqDialogOpen}
        handleSaveTagsForFaq={handleSaveTagsForFaq}
        setEditingFaq={setEditingFaq}
        editingFaq={editingFaq}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor:
              snackbar.severity === 'error' ? '#ff4c4c' : 'green',
            color: snackbar.severity === 'error' ? '#fefefe' : 'white',
            textAlign: 'center',
          },
        }}
      />
    </>
  );
}

export default FaqsMappingDetails;
