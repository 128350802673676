import React, { useState } from 'react';
import {
  Card,
  // Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Chip,
  // Switch,
} from '@mui/material';
import { format } from 'date-fns';
import { Edit } from '@mui/icons-material';
import { Row, Col } from 'react-bootstrap';
import JSONViewer from './JSONViewer';

function MappingEditForm({
  details,
  editOpen,
  setEditOpen,
  handleSaveEdit,
  PRODUCT_TYPES,
}) {
  const [editData, setEditData] = useState({
    mappingType: details.mappingType,
    mappingId: details.mappingId,
  });

  const handleEditClick = () => setEditOpen(true);

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditData((prev) => ({ ...prev, [name]: value }));
  };
  // const handleToggleChange = (event) => {
  //   setEditData((prev) => ({ ...prev, isActive: event.target.checked }));
  // };

  const formatText = (text) => {
    return text
      .toLowerCase() // Convert to lowercase
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
  };

  return (
    <Card variant="outlined" className="p-3">
      <Row>
        <Col style={{ textAlign: 'right' }}>
          <IconButton onClick={handleEditClick} size="small">
            <Edit />
          </IconButton>
        </Col>
      </Row>
      <Row className="t-4">
        <Col>
          <p className="card-content-align">
            <span>Id</span>
            <br />
            <b>{details._id}</b>
            <b>
              <JSONViewer title="TagIds" jsonData={details} />
            </b>
          </p>
        </Col>
        <Col>
          <p className="card-content-align">
            <span>Mapping Type</span>
            <br />
            <b>{formatText(details.mappingType)}</b>
          </p>
        </Col>
        <Col>
          <p className="card-content-align">
            <span>Mapping ID</span>
            <br />
            <b>{details.mappingId}</b>
          </p>
        </Col>
        <Col>
          <p className="card-content-align">
            <span>Created At</span>
            <br />
            <b>{format(new Date(details.createdAt), 'yyyy-MM-dd')}</b>
          </p>
        </Col>
        <Col>
          <p className="card-content-align">
            <span>Updated At</span>
            <br />
            <b>{format(new Date(details.updatedAt), 'yyyy-MM-dd')}</b>
          </p>
        </Col>

        <Col>
          <p className="card-content-align">
            <span>Status</span>
            <br />
            <Chip
              label={details.isActive ? 'Active' : 'Inactive'}
              style={{
                backgroundColor: details.isActive ? 'green' : 'red',
                color: 'white',
                fontWeight: 'bold',
              }}
            />
          </p>
        </Col>
      </Row>
      <Dialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Mapping</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Mapping Type</InputLabel>
            <Select
              name="mappingType"
              value={editData.mappingType}
              onChange={handleEditChange}
            >
              <MenuItem value="CLIENT">CLIENT</MenuItem>
              <MenuItem value="PRODUCT_TYPE">PRODUCT TYPE</MenuItem>
            </Select>
          </FormControl>
          {editData.mappingType === 'CLIENT' ? (
            <TextField
              name="mappingId"
              label="Mapping ID"
              fullWidth
              value={editData.mappingId}
              onChange={handleEditChange}
              margin="normal"
            />
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel>Mapping ID</InputLabel>
              <Select
                name="mappingId"
                value={editData.mappingId}
                onChange={handleEditChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Adjust height for 5-6 items before scrolling
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                {Object.entries(PRODUCT_TYPES).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              name="isActive"
              value={editData.isActive ? 'true' : 'false'}
              onChange={(event) =>
                setEditData((prev) => ({
                  ...prev,
                  isActive: event.target.value === 'true',
                }))
              }
            >
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleSaveEdit(editData)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default MappingEditForm;
