import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import {
  Card,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  Tooltip,
} from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import { Edit, Delete, Add } from '@mui/icons-material';

function MappingFaqsSection({
  details,
  faqList,
  onFaqTagChange,
  onSaveFaqMapping,
  onFaqChange,
  isFaqDialogOpen,
  setIsFaqDialogOpen,
  selectedFaqs,
  setSelectedFaqs,
  selectedFaqTags,
  setSelectedFaqTags,
  handleSaveTagsForFaq,
  setEditingFaq,
  editingFaq,
}) {
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    faqId: null,
  });

  const availableFaqs = useMemo(() => {
    const selectedFaqIds = selectedFaqs.map((faq) => faq.value);
    return faqList
      .filter(
        (faq) =>
          !selectedFaqIds.includes(faq._id) &&
          (!selectedFaq || faq._id !== selectedFaq.value)
      )
      .map((faq) => ({
        value: faq._id,
        label: faq.question,
        authorName: faq.authorName,
        visibility: faq.visibility,
        isActive: faq.isActive,
        answer: faq.answer,
        answerType: faq.answerType,
      }));
  }, [faqList, selectedFaqs, selectedFaq]);

  const handleFaqChange = (faqOption) => {
    if (!faqOption) return;
    setSelectedFaq(faqOption);
    // Ensure previously selected FAQs are retained
    onFaqChange([...selectedFaqs, faqOption]);
  };

  const handleFaqTagChange = (faqId, selectedTags) => {
    if (!faqId) return;
    const updatedTags = selectedTags.map((tag) => ({
      value: tag.value,
      label: tag.label,
    }));
    setSelectedFaqTags((prev) => ({
      ...prev,
      [faqId]: updatedTags,
    }));
    onFaqTagChange(faqId, updatedTags);
  };

  const handleDeleteFaq = (faqId) => {
    setConfirmDelete({ open: true, faqId });
  };

  const handleSaveFaqMapping = (updatedFaqs, updatedTags) => {
    onSaveFaqMapping(updatedFaqs, updatedTags);
    setIsFaqDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    const { faqId } = confirmDelete;
    if (!faqId) return;

    // Create updated lists without the deleted FAQ
    const updatedFaqs = selectedFaqs.filter((faq) => faq.value !== faqId);
    const updatedTags = { ...selectedFaqTags };
    delete updatedTags[faqId];

    setSelectedFaqs(updatedFaqs);
    setSelectedFaqTags(updatedTags);
    setConfirmDelete({ open: false, faqId: null });
    handleSaveFaqMapping(updatedFaqs, updatedTags);
  };

  const customSelectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
    control: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <Card variant="outlined" className="p-3">
      <Row>
        <Col>
          <Typography
            variant="h5"
            className="mb-4"
            style={{ textAlign: 'left' }}
          >
            Questions
          </Typography>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <IconButton onClick={() => setIsFaqDialogOpen(true)} size="small">
            <Add />
          </IconButton>
        </Col>
      </Row>

      {/* Table for viewing selected FAQs and their tags */}
      {selectedFaqs.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Title</b>
                </TableCell>
                <TableCell>
                  <b>Tags</b>
                </TableCell>
                <TableCell>
                  <b>Answer</b>
                </TableCell>
                <TableCell>
                  <b>authorName</b>
                </TableCell>
                <TableCell>
                  <b>Visibility</b>
                </TableCell>
                <TableCell>
                  <b>Status</b>
                </TableCell>
                <TableCell>
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedFaqs.map((faq) => (
                <TableRow key={faq.value}>
                  <TableCell>{faq.label}</TableCell>
                  <TableCell>
                    {editingFaq === faq.value ? (
                      <Select
                        isMulti
                        options={details.viewModel.tags.map((tag) => ({
                          value: tag._id,
                          label: tag.label,
                        }))}
                        value={selectedFaqTags[faq.value] || []}
                        onChange={(selectedTags) =>
                          handleFaqTagChange(faq.value, selectedTags)
                        }
                        styles={customSelectStyles}
                        menuPortalTarget={document.body}
                      />
                    ) : selectedFaqTags[faq.value].length > 0 ? (
                      selectedFaqTags[faq.value].map((tag) => (
                        <Chip
                          key={tag.value}
                          label={tag.label}
                          style={{
                            margin: '2px',
                            backgroundColor: 'blue',
                            color: 'white',
                          }}
                        />
                      ))
                    ) : (
                      'No Tags'
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: 200,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      position: 'relative',
                    }}
                  >
                    <Tooltip
                      title={
                        faq.answerType === 'HTML'
                          ? atob(faq.answer)
                          : faq.answer
                      }
                      placement="top"
                      PopperProps={{
                        modifiers: [
                          {
                            name: 'zIndex',
                            enabled: true,
                            options: {
                              zIndex: 1500, // Ensure it's above other elements
                            },
                          },
                        ],
                      }}
                    >
                      <span
                        style={{
                          display: 'block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          cursor: 'pointer', // Ensures it looks interactive
                        }}
                      >
                        {faq.answerType === 'HTML'
                          ? atob(faq.answer)
                          : faq.answer}
                      </span>
                    </Tooltip>
                  </TableCell>

                  <TableCell>{faq.authorName}</TableCell>
                  <TableCell>{faq.visibility}</TableCell>
                  <TableCell>
                    <Chip
                      label={faq.isActive ? 'Active' : 'Inactive'}
                      style={{
                        backgroundColor: faq.isActive ? 'green' : 'red',
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {editingFaq === faq.value ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleSaveTagsForFaq(faq.value)}
                      >
                        Save
                      </Button>
                    ) : (
                      <IconButton
                        onClick={() => setEditingFaq(faq.value)}
                        size="small"
                      >
                        <Edit />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => handleDeleteFaq(faq.value)}
                      size="small"
                    >
                      <Delete color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        open={isFaqDialogOpen}
        onClose={() => setIsFaqDialogOpen(false)}
        maxWidth={false}
        PaperProps={{
          sx: { width: '900px', maxWidth: '900px', maxHeight: '70%' },
        }}
      >
        <DialogTitle>Select FAQ and Tags</DialogTitle>
        <DialogContent sx={{ overflowY: 'auto', paddingBottom: 0 }}>
          <Typography>Select FAQ:</Typography>
          <div style={{ marginBottom: '10px' }}>
            <Select
              options={availableFaqs}
              value={selectedFaq}
              onChange={handleFaqChange}
              styles={customSelectStyles}
              menuPortalTarget={document.body}
            />
          </div>

          {selectedFaq && (
            <div style={{ marginTop: '20px' }}>
              <Typography>Select Tags:</Typography>
              <Select
                isMulti
                options={details.viewModel.tags.map((tag) => ({
                  value: tag._id,
                  label: tag.label,
                }))}
                value={selectedFaqTags[selectedFaq.value] || []}
                onChange={(selectedTags) =>
                  handleFaqTagChange(selectedFaq.value, selectedTags)
                }
                styles={customSelectStyles}
                menuPortalTarget={document.body}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsFaqDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => handleSaveFaqMapping(selectedFaqs, selectedFaqTags)}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDelete.open}
        onClose={() => setConfirmDelete({ open: false, faqId: null })}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this FAQ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDelete({ open: false, faqId: null })}
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default MappingFaqsSection;
